import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useVehicleCheckList({ vehicleId }) {
  // Use toast
  const toast = useToast()

  const refVehicleCheckTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'outcome', label: 'Outcome' },
    { key: 'createdAt', label: 'Date' },
    { key: 'mileage', label: 'Mileage' },
    { key: 'driver', label: 'Driver' },
    { key: 'actions' },

  ]
  const perPage = ref(10)
  const totalVehicleChecks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refVehicleCheckTable.value ? refVehicleCheckTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVehicleChecks.value,
    }
  })

  const refetchData = () => {
    refVehicleCheckTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchVehicleChecks = (ctx, callback) => {
    store
      .dispatch('app-vehicle/fetchVehicleChecks', {
        vehicleId,
        limit: perPage.value,
        page: currentPage.value,
        sort: `c.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { vehicleChecks, meta } = response.data

        callback(vehicleChecks)
        totalVehicleChecks.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching vehicles checks',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCheckOutcomeVariant = outcome => {
    if (outcome === true) return 'primary'
    if (outcome === false) return 'warning'
    return 'secondary'
  }

  const resolveCheckOutcomeIcon = outcome => {
    if (outcome === true) return 'CheckIcon'
    if (outcome === false) return 'XIcon'
    return 'MapPinIcon'
  }

  return {
    fetchVehicleChecks,
    tableColumns,
    perPage,
    currentPage,
    totalVehicleChecks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVehicleCheckTable,

    resolveCheckOutcomeVariant,
    resolveCheckOutcomeIcon,
  }
}

<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="vehicleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching vehicle data
      </h4>
      <div class="alert-body">
        No vehicle found with this vehicle id. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'vehicles-list'}"
        >
          Vehicle List
        </b-link>
        for other vehicles.
      </div>
    </b-alert>

    <template v-if="vehicleData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <vehicle-info-card
            :vehicle-data="vehicleData"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="6"
          md="6"
        >
          <vehicle-check-history-card
            :vehicle-id="vehicleData.id"
          />
        </b-col>

      </b-row>
      </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import vehicleStoreModule from '@/views/vehicle/vehicleStoreModule'
import vehicleInfoCard from '@/views/vehicle/vehicles-view/VehicleInfoCard.vue'
import vehicleCheckHistoryCard from '@/views/vehicle/vehicles-view/VehicleCheckHistoryCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    vehicleInfoCard,
    vehicleCheckHistoryCard,
  },
  methods: {
    handleVehicleDataUpdate(data) {
      this.vehicleData = data
    },
  },
  setup() {
    const vehicleData = ref(null)

    const VEHICLE_APP_STORE_MODULE_NAME = 'app-vehicle'

    // Register module
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-vehicle/fetchVehicle', { id: router.currentRoute.params.id })
      .then(response => {
        vehicleData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          vehicleData.value = undefined
        }
      })
    return {
      vehicleData,
    }
  },
}
</script>

<style>

</style>
